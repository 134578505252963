.gallery-section {
  background-color: #fafafa; /* Ash color background */
  padding: 40px 20px;
  text-align: center;
}

/* Heading and subheading styling */
.gallery-heading {
  color: #fb033c;
  font-size: 2rem;
  margin-bottom: 20px;
}

.gallery-subheading {
  font-weight: normal;
  font-size: 1.2rem;
  margin-bottom: 30px;
}

/* Container for the entire gallery */
.gallery-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fcd400; /* Yellow color */
}

/* Individual image card styling */
.image-card {
  background-color: #ffffff;
  border-radius: 20px;
  overflow: hidden;
  flex: 1 1 calc(33.333% - 20px); /* Three images per row */
  margin: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}

.image-card:hover {
  transform: scale(1.05);
}

.image_card {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 20px 20px 0 0;
}

.image-title {
  color: black;
  padding: 10px;
  font-size: 1.25rem;
  text-align: center;
  background-color: #fcd400; /* Yellow color */
}

.image-description {
  padding: 10px;
  font-size: 1rem;
  color: #000;
  text-align: center;
  background-color: #fafafa; /* Ash color background */
}

/* Responsive styles */
@media (max-width: 1024px) {
  .image-card {
    flex: 1 1 calc(45% - 20px); /* Two images per row */
  }
}

@media (max-width: 768px) {
  .gallery-heading {
    font-size: 1.75rem;
  }

  .gallery-subheading {
    font-size: 1rem;
  }

  .image-card {
    flex: 1 1 calc(100% - 20px); /* One image per row */
    margin: 10px 0;
  }
}

@media (max-width: 480px) {
  .gallery-heading {
    font-size: 1.5rem;
  }

  .gallery-subheading {
    font-size: 0.875rem;
  }

  .image-title {
    font-size: 1rem;
    padding: 8px;
  }

  .image-description {
    font-size: 0.875rem;
    padding: 8px;
  }
}
