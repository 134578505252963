.marqueeleft-container {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
  }
  
  .schoolimg_marqueeleft {
    display: flex;
    gap: 50px;
    animation: schoolmarqueeleft 10s linear infinite;
    /* width: 55%; */
  }

  .school_name{
    padding: 10px 10px 10px 10px;
    white-space: normal;
    word-wrap: break-word;
    text-align: center;
    max-width: 100%;
    color: white;
  }
  
  .schoolimg_marqueeleft img {
    width: 100%;
    border-radius: 20px 20px 0 0;
  }
  
  @keyframes schoolmarqueeleft {
    0% {
      transform: translateX(0);
    }
  
    100% {
      transform: translateX(-50%);
    }
  }
  
  @media screen and (min-width: 0px) and (max-width: 767px) {

    .schoolimg_marqueeleft {
        width: 50%;
        display: flex;
        gap: 10px;
        animation: schoolmarqueeleft 10s linear infinite;
      }

      @keyframes schoolmarqueeleft {
        0% {
          transform: translateX(0);
        }
      
        100% {
          transform: translateX(-250%);
        }
      }
  
    .schoolimg_marqueeleft img {
      width: 100%;
      border-radius: 10px 10px 0 0;
    }
  
  }