.AI_and_ML_intro{
    font-size: large;
}

.AI_and_ML_blue{
    background-color: #D3F3FE;
}

.AI_and_ML_icons{
    display: flex;
    justify-content: space-evenly;
    font-size: x-large;
}