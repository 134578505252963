/* .aiandrobo-header {

  width: 100%;
  height: 100vh;
  background-image: url('../../images/new 01.jpg');
  background-size: 100% 100%;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  background-repeat:no-repeat

}

.overlay {
  position: absolute;
  top: 0; 
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  
}

.aicontent {
  margin-top:-100px;
  padding: 20px;
  margin-left:60px;
}

.header-title {
  color:#fcd400;
  font-family: "Love Ya Like A Sister", cursive;
  font-size: 3rem; 
  text-align: left;
  font-weight:normal;

  margin-top:300px;
  
}

.header-description {
  text-align: left;

  font-size: 1rem;
  margin-top: 10px;

}
.aiandrobohbtn{
  margin-top:270px;
  margin-right:900px;
  padding:30px;
  z-index: 1;
}
.aiandrobohbtn button{
  height:40px;
  width:150px;
  color:white;
}
.aiandrobohbtn button:nth-child(2){
  background-color:black;
  margin-left:20px;
  border:none;
  border-radius:70px;

}
.aiandrobohbtn button:nth-child(1){
  background-color:#fcd400;
  margin-left:20px;
  border:none;
  border-radius:70px;
} */


#aiandrobo-header {


  background-image: url('../../images/new 01.jpg');

}