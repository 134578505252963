#text-colour-lightblue{
    color: #3ABBF1;
}

#home_orange_section{
    background-color: #FF822E;
}

.gradient {
    color: white;
    background-image: linear-gradient(#bb32fa, #6975dd);
    padding-left: 18%;
    padding-right: 18%;
    position: relative; /* Ensures the container is a positioned element */
}

.home_img_1 {
    width: 100%;
    position: relative;
    margin-bottom: -124px; /* Pull the image out of the container */
}

.text_under_pinkstroke{
    color: black;
    font-family: var(--ff-Love);
}

/* Media queries for different screen sizes */
@media (max-width: 1200px) {
    .gradient {
        padding-left: 10%;
        padding-right: 10%;
    }
    .home_img_1 {
        margin-bottom: -100px;
    }
}

@media (max-width: 992px) {
    .gradient {
        padding-left: 5%;
        padding-right: 5%;
    }
    .home_img_1 {
        margin-bottom: -145px;
    }
}

@media (max-width: 768px) {
    .gradient {
        padding-left: 3%;
        padding-right: 3%;
    }
    .home_img_1 {
        margin-bottom: -60px;
    }
}

@media (max-width: 576px) {
    .gradient {
        padding-left: 2%;
        padding-right: 2%;
    }
    .home_img_1 {
        margin-bottom: -40px;
    }
}

@media (max-width: 400px) {
    .gradient {
        padding-left: 1%;
        padding-right: 1%;
    }
    .home_img_1 {
        margin-bottom: -30px;
    }
}


/* .home_form_background{
    padding: 50px;
    background-image: url(https://ik.imagekit.io/mhcockpit1/MH%20Intellect%20Images/Home%20Page/FORM.png?updatedAt=1716904059646);
    background-repeat: no-repeat;
    background-size: cover;
} */

.home_button{
    border: none;
    border-radius: 20px;
    background-color: #ff9e00;
    color: white;
    padding: 6px;
}

.home_button:hover{
    background-color: #ffb554;
}

.home_headings_flex{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px; /* Adjust the gap between items if needed */
}

.aeronautics {
    color: #9bd542; /* Red color */
    text-decoration: none;
}

.aerospace {
    color: #44d0a1; /* Green color */
    text-decoration: none;
}

.ai-ml {
    color: #3fd5e3; /* Blue color */
}

.robotics {
    color: #3da8e0; /* Magenta color */
    text-decoration: none;
}

.separator {
    color: #40a4de; /* Black color */
}

.card_1{
    background-color: #88c7f2;
    border-radius: 20px;
    /* height: 280px; */
}

.card_2{
    background-color: #ff9667;
    border-radius: 20px;
    /* height: 280px; */
}

.card_3{
    background-color: #fe5995;
    border-radius: 20px;
    /* height: 280px; */
}

.card_4 {
    background-color: #67ffa4;
    border-radius: 20px;
    /* height: 280px; */
}

.school_image{
    border-radius: 15px 15px 0 0;
}

.form_heading{
    background-color: #3bbaef;
    text-align: center;
    padding: 10px;
    color: white;
    border-radius: 15px 15px 0 0;
}

@media screen and (min-width: 0px) and (max-width: 767px) {

    .home_img_1{
        width: 100%;
        margin-bottom: -76px;
    }

    .home_headings_flex{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px; /* Adjust the gap between items if needed */
    }

    .home_headings_flex h4{
        font-size: small;
    }

}