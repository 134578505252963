/* .marquee-container {
  white-space: nowrap;
  overflow: hidden;
  position: relative;
}

.marquee-img {
  display: inline-block;
  margin-right: 20px;
  animation: marquee 30s linear infinite;
  border-radius: 20px;
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-3900%);
  }
} */


/* .marqueeleft-container {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  position: relative;
}

.marqueeleft {
  display: flex;
  gap: 10px;
  animation: marqueeleft 20s linear infinite;
}

.marqueeleft img {
  width: 25%;
  border-radius: 20px;
}

@keyframes marqueeleft {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-50%);
  }
} */

.marquee_left_container {
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  width: 100%;
  position: relative;
}

.marquee_left_content {
  display: inline-block;
  white-space: nowrap;
  animation: marqueeleft 20s linear infinite;
}

.marquee_left_content img {
  display: inline-block;
  margin-right: 10px; /* Adjust margin as needed */
  width: 25%;
  border-radius: 20px;
}

@keyframes marqueeleft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}


@media screen and (min-width: 0px) and (max-width: 767px) {

  /* .marqueeleft img {
    width: 25%;
    border-radius: 10px;
  } */

  .marquee_left_content img {
    width: 30%;
    border-radius: 10px;
  }

}