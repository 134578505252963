.our_governance_text {
    border-radius: 0 0 15px 15px;
    /* box-shadow: 0 0 10px black; */
}

.our_governance_img{
    border-radius: 30px 30px 0 0;
}

.our_governance_img_hover {
    transition: 1s ease;
    border-radius: 15px;
    box-shadow: 0 0 10px grey;
    border-radius: 15px;
}

.our_governance_img_hover:hover {
    transform: scale(1.02);
    transition: 1s ease;
}