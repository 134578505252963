.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/* .custom-navbar {
  transition: background-color 0.3s ease-in-out, padding 0.3s ease-in-out;
}

.custom-toggle {
  border: 1px solid #fff;
}

.custom-toggle .navbar-toggler-icon {
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath stroke="rgba%28255, 255, 255, 1%29" stroke-width="2" linecap="round" linejoin="round" d="M4 7h22M4 15h22M4 23h22"%3E%3C/path%3E%3C/svg%3E');
} */


.custom-navbar {
  transition: background-color 0.3s ease-in-out, padding 0.3s ease-in-out;
}

.custom-toggle {
  border: 1px solid #fff;
}

.custom-toggle .navbar-toggler-icon {
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath stroke="rgba%28255, 255, 255, 1%29" stroke-width="2" linecap="round" linejoin="round" d="M4 7h22M4 15h22M4 23h22"%3E%3C/path%3E%3C/svg%3E');
}

.custom-collapse {
  text-align: center;
}

.nav-items, .nav-icons {
  display: flex;
  justify-content: center;
  gap: 20px; /* Adjust gap as needed */
}

.nav-icons {
  margin-top: 10px; /* Adjust top margin as needed */
}

@media (max-width: 991px) {
  .nav-items, .nav-icons {
    flex-direction: column;
    align-items: center;
    gap: 10px; /* Adjust gap as needed for smaller screens */
    font-size: large;
    animation: pop 0.5s ease-in-out;
    /* transform: translateX(20deg); */
    /* transform: translateY(10vh); */
  }
  @keyframes pop {
    0% {
      transform: scale(0.5) translateX(10vh);
      opacity: 0;
    }
    50% {
      transform: scale(1.1) translateZ(20vh);
      opacity: 0.7;
    }
    100% {
      transform: scale(1) translateZ(10vh);
      opacity: 1;
    }
  }
}



.icon-label {
  display: inline-block; /* Hide labels by default for smaller screens */
}

/* Hide <br> by default */
.navbar_icon br {
  display: none;
}

/* Tablets (portrait and landscape) */
@media (min-width: 576px) {
  .icon-label {
    display: inline-block; /* Hide labels for small screens (tablets) */
  }
}

/* Small laptops/desktops */
@media (min-width: 768px) {
  .icon-label {
    display: inline-block; /* Hide labels for medium screens (small laptops) */
  }
}

/* Large laptops/desktops */
@media (min-width: 992px) {
  .icon-label {
    display: inline-block; /* Hide labels for large screens (large laptops/desktops) */
  }
}

/* Extra large laptops/desktops */
@media (min-width: 1200px) {
  .icon-label {
    display: inline; /* Show labels for extra large screens */
  }
  .navbar_icon br {
    display: inline-block; /* Show <br> for extra large screens */
  }
}
