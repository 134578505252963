.project_img {
    border-radius: 15px;
    box-shadow: 0 0 10px black;
}

.project_img_hover {
    transition: 1s ease;
    border-radius: 15px;
    /* box-shadow: 0 0 5px grey; */
}

.project_img_hover:hover {
    transform: scale(1.02);
    transition: 1s ease;
}