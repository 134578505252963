.lab_heading{
    color: #ff6788;
}

.aeronautics_aerospace{
    border-radius: 20px;
}

.lab_button_1{
    border: none;
    border-radius: 20px;
    background-color: #ff6788;
    color: white;
    padding: 6px;
}

.lab_button_1:hover{
    background-color: #ff849f;
}

.lab_button_2{
    border: none;
    border-radius: 20px;
    background-color: #000000;
    color: white;
    padding: 6px;
    text-decoration: none;
}

.lab_button_2:hover{
    background-color: #333333;
}