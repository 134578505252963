.innovators-container {
    width: 90%;
    background: linear-gradient(to right, #FFF7E8, #E6E6FA, #E0F7FA);
    padding: 2rem;
    border-radius: 1.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
    overflow-x: hidden; 
}

@media (min-width: 768px) {
    .innovators-container {
        padding: 4rem;
    }
}

.innovators-content {
    max-width: 1120px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

@media (min-width: 768px) {
    .innovators-content {
        flex-direction: row;
    }
}

.image-container {
    width: 100%;
    margin-bottom: 2rem;
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

@media (min-width: 768px) {
    .image-container {
        width: 50%;
        margin-bottom: 0;
        padding-right: 2rem;
        box-sizing: border-box; /* Ensure padding doesn't cause overflow */
    }
}

.image-wrapper {
    width: 100%;
    border-radius: 1.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.5s ease-in-out;
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

.image-wrapper:hover {
    transform: scale(1.05);
}

.image {
    width: 100%;
    height: auto;
    border-radius: 1.5rem;
    display: block; /* Ensure the image is block-level to prevent any overflow issues */
}

.text-container {
    width: 100%;
    text-align: center;
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

@media (min-width: 768px) {
    .text-container {
        width: 50%;
        text-align: left;
    }
}

.title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #E53935;
}

.description {
    font-size: 1.125rem;
    color: #4A4A4A;
}

.extra-element {
    display: flex;
    justify-content: center;
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

@media (min-width: 768px) {
    .extra-element {
        justify-content: flex-start;
    }
}
