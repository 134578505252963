.projects_page_heading{
    color: #565EFF;
}

.projects_page_benefits span{
    color: #E355FF;
}

.projects_page_description p{
  font-weight: 600;
  font-size: large;
}

/* .projects_blue_stickynote{
    background-image: url("/public/Projects/Sticky\ Notes.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; 
    width: 100%;
    height: 100vh; 
    object-fit: cover; 
}

.projects_pink_stickynote{
    background-image: url("/public/Projects/Sticky\ Notes\ Pink.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; 
    width: 100%;
    height: 100vh; 
    object-fit: cover; 
} */

.projects_page_note {
    background-size: cover;
    background-position: center;
    width: 300px;
    height: 300px;
    margin: 15px;
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .projects_blue_stickynote {
    background-image: url('/public/Projects/Sticky\ Notes.png');  /* Replace with your image path */
  }
  
  .projects_pink_stickynote {
    background-image: url('/public/Projects/Sticky\ Notes\ Pink.png');  /* Replace with your image path */
  }
  
  .projects_page_content {
    text-align: start;
    padding: 30px;
    transform: skewY(-5deg) skewX(5deg);
    color: white;
  }

  .projects_page_potential_scope h1{
    color: #565EFF;
  }

  .projects_page_potential_scope span{
    text-decoration: underline #565EFF;
    color: black;
  }

  .potential_scope{
    font-weight: 600;
    font-size: large;
  }

  .potential_scope span{
    color: #565EFF;
  }

  .potential_scope_1{
    font-weight: 600;
    font-size: larger;
  }

  .potential_scope_1 span{
    color: #565EFF;
  }

  .potential_scope_2{
    font-weight: 600;
    font-size: larger;
  }

  .potential_scope_2 span{
    color: #E355FF;
  }

  .potential_scope_3{
    font-weight: 600;
    font-size: larger;
  }

  .potential_scope_3 span{
    color: #565EFF;
  }
  