/* .marqueeright-container {
    white-space: nowrap;
    overflow: hidden;
    position: relative;
  }
  
  .marqueeright-img {
    display: inline-block;
    margin-right: 20px;
    animation: marqueeright 30s linear infinite;
    border-radius: 20px;
  }
  
  @keyframes marqueeright {
    0% { transform: translateX(-3400%); }
    100% { transform: translateX(0); }
  } */

  /* .marqueeright-container {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
  }
  
  .marqueeright {
    display: flex;
    gap: 10px;
    animation: marqueeright 20s linear infinite;
  }
  
  .marqueeright img {
    width: 25%;
    border-radius: 20px;
  }
  
  @keyframes marqueeright {
    0% {
      transform: translateX(-50%);
    }
  
    100% {
      transform: translateX(0);
    }
  } */

  .marquee_right_container {
    overflow: hidden;
    white-space: nowrap;
    box-sizing: border-box;
    width: 100%;
    position: relative;
  }
  
  .marquee_right_content {
    display: inline-block;
    white-space: nowrap;
    animation: marqueeright 20s linear infinite;
  }
  
  .marquee_right_content img {
    display: inline-block;
    margin-right: 10px; /* Adjust margin as needed */
    width: 25%;
    border-radius: 20px;
  }
  
  @keyframes marqueeright {
    0% {
      transform: translateX(-50%);
    }
  
    100% {
      transform: translateX(0);
    }
  }
  

  @media screen and (min-width: 0px) and (max-width: 767px) {

    /* .marqueeright img {
      width: 25%;
      border-radius: 10px;
    } */

    .marquee_right_content img {
      width: 30%;
      border-radius: 10px;
    }
  
  }