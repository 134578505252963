@import url('https://fonts.googleapis.com/css2?family=Bubblegum+Sans&family=League+Spartan:wght@100..900&family=Love+Ya+Like+A+Sister&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

:root {  
    --light-yellow: hsl(42, 94%, 55%);  
    --light-red: hsl(346, 100%, 54%); 
    --blue-violet:hsl(260, 100%, 67%);  
    --stock-green: #5ab27fce;
    --stock-green-hover: #5ab27f;   
     
    --text-white: #fff;
    --text-black: #0a0a0a;
    --text-color: #545454;
    --text-gray: #aba8b0;
    --text-gray-light: #484848;
  
    --ff-league: 'League Spartan', sans-serif;
    --ff-poppins: 'Poppins', sans-serif; 
    --ff-Love:"Love Ya Like A Sister", cursive;  
     
    --gradient: linear-gradient(-90deg, hsla(218, 80%, 90%, 0.36) 0%, hsla(214, 82%, 85%, 0.251) 50%, hsla(219, 79%, 85%, 0.108) 100%);
  
  } 

section {
    padding: 5rem 5%;
    margin: auto;    
  } 
  
  .text-primary {
    color: white !important; 
    font-family: var( --ff-Love);   
  }   
   
  .title{  
    color: var(--text-black) !important; 
    font-family: var(--ff-poppins);  
    position: relative; 
    margin-bottom: 1rem;
  }  
   
  .title:after{ 
    position: absolute;
    bottom: -1rem;  
    left: 50%;
    transform: translateX(-50%);
    content: "";
    background: var(--light-yellow);
    width: 10rem;
    height: 0.4rem; 
    border-radius: 1rem;
  } 
   
  .text{ 
    color: var(--text-color) !important;  
    text-align: justify;
  }
   
  
  .btn-primary { 
    background-color: var(hsl(42, 94%, 55%)) !important;
    border: none; 
    color: white; 
    border-radius: 5rem; 
    padding: 6px 12px ; 
    margin: 0.5rem 0;
  }

/* Home section start */
 
  .hero-section { 
    background-image: url("../public/Aeronautics_and_Aerospace/images/Aeronautics\ &\ Aerospace\ Lab.jpg"); 
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center; 
    width: 100%;
    height: 100vh; 
    object-fit: cover; 
    color: white;
  }   
  
  
  .hero-btns a:first-child {
    background-color: var( --light-yellow) !important; 
    color: black !important;  
  }
  
  .hero-btns a:last-child{
    background-color: black; 
    color: white !important;
  } 
  
  .hero-btn {
    overflow: hidden; 
    border: none;
    position: relative; 
  }
  
  .has-before,
  .has-after {
    position: relative;
    z-index: 1;
  }
  
  .has-before::before,
  .has-after::after {
    position: absolute;
    content: "";
  }
  
  .hero-btn::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: var(--gradient);
    z-index: -1;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;  
    border: none;   
  }
  
  .hero-btn:is(:hover, :focus)::before {
    transform: translateX(0); 
  }  
   
  /* Home section end */
     
   
  
   /* Gallery section Start */
  .gallery img{
    transition: transform 0.5s ease;
  }
  
  .gallery img:hover {
    transform: scale(1.05);
  } 
   
  .fadeInUp {
    animation: fadeInUp 0.5s ease forwards;
    opacity: 0;
    transform: translateY(20px);
  }
  
  /* Gallery section end */
  
      
  /* FAQs Start */ 
  .content{   
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0,1,0,1);
  } 
  
  .content.show{ 
     height: auto; 
     max-height: 9999px; 
     transition: all 0.5s cubic-bezier(1,0,1,0);
  }   
  /* FAQs end */ 
     
   
  
  @media (max-width: 1200px) {
      .section {
          padding: 5rem 5%;
      } 
    }
    
  @media (max-width: 992px) { 
     .section {
         padding: 4rem 5%;
      }  
  
    }
    
  @media (max-width: 767px) {   
     .section {
        padding: 3rem 4%;
     }   
     html { 
      font-size: 73%; 
    }
    } 
    
  @media (max-width: 575px) { 
      .section {
        padding: 2.5rem 3%;
      }  
   
      html { 
        font-size: 73%; 
      } 
       
      .hero-section { 
        background-image: url("../public/Aeronautics_and_Aerospace/images/Aeronautics\ &\ Aerospace\ Lab-2.jpg"); 
      }  
    }