.our_products_cover {
    width: 100%;
}

.our_products_orange {
    background-color: orange;
    border-radius: 30px;
    color: white;
    box-shadow: 0 8px 10px rgba(0, 0, 0, 0.5), 0 6px 20px rgba(0, 0, 0, 0.1);
}

.product_gradient {
    margin-top: -100px;
    color: white;
    background-image: linear-gradient(#bb32fa, #6975dd);
    padding-left: 10%;
    padding-right: 10%;
    text-align: justify;
}

/* .product_why_us {
    margin-top: -150px;
} */

.text_orange {
    color: orange;
}

.text_lightblue {
    color: #08accc;
    font-size: large;
    text-align: justify;
}

.product_white{
    background-color: white;
    border-radius: 25% 25% 32% 53% / 0% 0% 50% 26%;
    padding-bottom: 100px;
}

.product_purple{
    background-color: #7064c4;
}

/* Small devices (phones, less than 600px) */
@media (max-width: 600px) {
  .product_white {
      border-radius: 20% 20% 30% 50% / 0% 0% 40% 20%;
  }
}

/* Medium devices (tablets, less than 768px) */
@media (max-width: 768px) {
  .product_white {
      /* border-radius: 22% 22% 31% 52% / 0% 0% 45% 23%; */
      border-radius: 126% 22% 31% 52% / 0% 0% 30% 23%;
  }
}

/* Large devices (desktops, 1024px and up) */
@media (min-width: 1024px) {
  .product_white {
      border-radius: 25% 25% 32% 53% / 0% 0% 50% 26%;
  }
}

@media screen and (min-width: 0px) and (max-width: 767px) {

    /* .product_why_us {
        margin-top: -350px;
    } */

    .text_lightblue {
        font-size: small;
    }

}

/* -------------------------------------hahini CSS------------------------------------- */

.image-slider {
    overflow: hidden;
    width: 100%;
    /* Width of three cards */
    /* height: 60vh; */
    /* Adjusted height for the card and text */
    /* position: relative; */
    /* margin: 0 auto; */
    /* Center the slider */
    perspective: 1000px;
    /* Add perspective for 3D effect */
    margin-top: 70px;
}

.image-track {
    display: flex;
    transition: transform 0.5s ease;
    margin-top: 100px;
}

.image-container {
    flex: 0 0 33.33%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: filter 0.3s ease, transform 0.3s ease;
    transform: scale(0.9) translateZ(0);
    /* Add translateZ(0) to ensure proper 3D transformation */
    z-index: 1;
}


.image-container img {
    width: 100%;
    height: 100;
    border-radius: 10px;
    /* Rounded corners for the image */
}

.image-container.focused {
    transform: scale(1.8);
    /* Scale the focused card */
    z-index: 2;
    /* Bring the focused card to the front */
}

.image-container.blurred img {
    filter: blur(5px);
}

.product_card {
    width: 160px;
    /* Card width */
    height: 120px;
    /* Adjust height for the card */
    padding: 10px;
    background: rgb(8, 173, 205);
    border-radius: 20px;
    /* Rounded corners for the card */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.product_card img {
    width: 100%;
    height: auto;
    /* Maintain aspect ratio */
    border-radius: 10px;
    /* Rounded corners for the image */
    object-fit: cover;
    /* Ensures the image covers the card without distortion */
}

.description {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 50px;
}

.card-text {
    margin-top: 10px;
    font-size: 14px;
    text-align: center;
}

.buy-now-button {
    margin-top: 10px;
    border-radius: 15px;
}

/* Responsive Design */
@media (max-width: 1200px) {
    .image-slider {
        width: 80%;
    }

    .product_card {
        width: 140px;
        height: 100px;
    }

    .card-text {
        font-size: 12px;
    }

    .image-container {
    flex: 0 0 40.33%;
}
}

@media (max-width: 992px) {
    .image-slider {
        width: 90%;
    }

    .product_card {
        width: 120px;
        height: 90px;
    }

    .card-text {
        font-size: 11px;
    }

    .image-container {
    flex: 0 0 40.33%;
}
}

@media (max-width: 768px) {
    .image-slider {
        width: 100%;
    }

    .product_card {
        width: 100px;
        height: 80px;
    }

    .card-text {
        font-size: 10px;
    }

    .image-container {
    flex: 0 0 40.33%;
}
}

@media (max-width: 576px) {
    .image-slider {
        width: 100%;
    }

    .product_card {
        width: 80px;
        height: 60px;
    }

    .card-text {
        font-size: 9px;
        /* Font size for the text */
        border-radius: 2px;
        /* Adjust the value to get the desired curvature */
        padding: 5px;
        /* Optional: Adds some padding to make the text look better inside rounded corners */
        background-color: #f0f0f0;
        /* Optional: Adds a background color for better visibility */
        display: inline-block;
        /* Optional: Ensures elements with these classes fit the content size */
    }

    .image-container {
    flex: 0 0 40.33%;
}

}

/* -------------------------------------hahini CSS------------------------------------- */

/*  -------------------------------------hahini CSS-------------------------------------  */

.testimonial-slider-container {
    position: relative;
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .testimonial-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  
  .testimonial-image-container {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 20px;
  }
  
  .testimonial-image {
    width: 130%;
    height: 100%;
  }
  
  .testimonial-info {
    text-align: left;
  }
  
  .testimonial-name {
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  .testimonial-review {
    font-style: italic;
    color: white;
  }
  
  .happy-clients {
    text-align: center;
    margin-top: 20px;
    color: white;
  }
  
  /* Responsive Design */
  @media (max-width: 1200px) {
    .testimonial-slider-container {
      position: relative;
      width: 95%;
    }
  }
  
  @media (max-width: 992px) {
    .testimonial-slider-container {
      position: relative;
      width: 90%;
    }
  }
  
  @media (max-width: 768px) {
    .testimonial-slider-container {
      position: relative;
      width: 95%;
    }
  
    .testimonial-card {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .testimonial-image-container {
      margin-right: 0;
      margin-bottom: 20px;
    }
  }
  
  @media (max-width: 576px) {
    .testimonial-image-container {
      width: 80px;
      height: 80px;
    }
  
    .testimonial-name {
      font-size: 16px;
    }
  
    .testimonial-review {
      font-size: 14px;
    }
  }

/*  -------------------------------------hahini CSS-------------------------------------  */

.aviation_kits_list{
  font-size: x-large;
  text-align: start;
}

@media screen and (max-width: 767px) {

  .aviation_kits_list{
    font-size: large;
  }

}