.photo-gallery-section {
    width:99%;
    text-align: center;
    padding: 20px;
    background-color:#ff822e;
    
}

.photo-gallery-heading {
    color: white;
}

.photo-gallery-subheading {
    font-style: italic;
}

.photo-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.photo-card {
    width: 100%; /* Adjust width to fit your design */
    max-width: 250px; /* Set maximum width for larger screens */
    margin: 10px;
    overflow: hidden;
    position: relative;
    cursor: pointer;
}

.photo {
    width: 100%;
    height: auto;
    transition: transform 0.5s ease;
}

.photo-title {
    background-color: white;
    color: black;
    padding: 10px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
}

.photo-description {
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 5px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    transition: opacity 0.3s ease;
    opacity: 0;
}

.photo-card:hover .photo-description {
    opacity: 1;
}

.animate {
    animation: dance 0.5s ease infinite alternate;
}

@keyframes dance {
    from {
        transform: rotateZ(2deg);
    }
    to {
        transform: rotateZ(-2deg);
    }
}

/* Media queries for responsiveness */

@media only screen and (min-width: 600px) {
    .photo-card {
        width: 48%; /* Adjust width for larger screens */
    }
}

@media only screen and (min-width: 768px) {
    .photo-card {
        width: 31%; /* Adjust width for tablets */
    }
}

@media only screen and (min-width: 1024px) {
    .photo-card {
        width: 23%; /* Adjust width for larger tablets and desktops */
    }
}
