.school_programs_gradient{
    color: white;
    background-image: linear-gradient(#bb32fa, #6975dd);
}

.MH_Intellect_Workshop{
    background: -webkit-linear-gradient(left, #32f3fa, #6975dd, #bb32fa, #6975dd, #fa3243, #ff0000);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.Fly_High_with_Innovation{
    color: #6975dd;
}

.school_program_heading_highlight span{
    background-color: #6975dd;
    color: white;
    border-radius: 10px;
    padding: 5px 20px;
}