.box-main {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.box-inner {
    color: black; 
    position: relative;   
    display: flex; 
    align-items: center; 
    justify-content: center;
    margin: 1.5rem; 
} 

.box-content {  
    height: 33vh;    
    width: 18rem; 
    display: flex; 
    align-items: end; 
    justify-content: center;   
    border: dotted 2px;
}

.aeromodelling-img, .dronetraining-img, .rcplane-img { 
    position: absolute;
    width: 100%;
    animation: demo 2s linear 2s infinite alternate;
    filter: saturate(1);
}

.aeromodelling-img { 
    margin-top: -25px;
}

.dronetraining-img { 
    margin-top: -60px; 
}

.rcplane-img {
    margin-top: -30px;
}

@keyframes demo {
    0% {
        left: 0px;
        top: 0px;
    }

    25% {
        left: 5px;
        top: 0px;
    }

    50% {
        left: 5px;
        top: 5px;
    }

    75% {
        left: 0px;
        top: 5px;
    }

    100% {
        left: 0px;
        top: 0px;
    }
}

@media (max-width: 1200px) {
    .box-content {
        height: 25vh;    
        width: 14rem; 
    }
}

@media (max-width: 992px) {
    .box-content {
        height: 20vh;     
        width: 12rem; 
    } 
    
    .dronetraining-img { 
        margin-top: -40px; 
    }
  
}

@media (max-width: 768px) {
    .box-content {
        height: 18vh;  
        width: 12rem;    
    }  
     
    .aeromodelling-img { 
        margin-top: -10px;
    }
    
    .dronetraining-img { 
        margin-top: -20px; 
    }
    
    .rcplane-img {
        margin-top: -15px;
    }
}

@media (max-width: 576px) {
    .box-content {
        height: 18vh;    
        width: 12rem; 
    }  
     
    .aeromodelling-img { 
        margin-top: -10px;
    }
    
    .dronetraining-img { 
        margin-top: -30px; 
    }
    
    .rcplane-img {
        margin-top: -15px;
    }
    
}
