.school_logo_marqueeleft-container {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    position: relative;
  }
  
  .school_logo_marqueeleft {
    display: flex;
    gap: 10px;
    animation: schoollogomarqueeleft 20s linear infinite;
  }
  
  .school_logo_marqueeleft img {
    width: 25%;
    border-radius: 20px;
  }
  
  @keyframes schoollogomarqueeleft {
    0% {
      transform: translateX(0);
    }
  
    100% {
      transform: translateX(-50%);
    }
  }
  
  @media screen and (min-width: 0px) and (max-width: 767px) {
  
    .school_logo_marqueeleft img {
      width: 25%;
      border-radius: 10px;
    }
  
  }