/* Container for the image and content */
.aiandrobocontent {
    display: flex;
    flex-wrap: wrap;
    padding: 20px;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    margin-top: 80px;
}

/* Content styles */
.para1_robo {
    flex: 1 1 300px;
    min-width: 300px;
    margin-left: 20px;
}

.para1_robo h1 {
    color: #fb033c;
    font-size: 2rem;
    margin-bottom: 20px;
}

.para1_robo p {
    text-align: left;
    font-size: 1.25rem;
    margin: 20px 0;
}

/* Button styles */
.aibutton {
    height: 40px;
    width: 200px;
    border-radius: 50px;
    background-color: #fb033c;
    border: none;
    color: white;
    cursor: pointer;
    margin-top: 20px;
}

/* Sub-section styles */
.pa {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    flex: 1 1 300px;
    min-width: 300px;
    margin-top: 80px;
}

.pa1, .pa2, .pa3, .pa4 {
    height: 30px;
    width: 190px;
    border-radius: 50px;
    background-color: #FF4040;
    color: white;
    margin: 15px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Responsive Design */
@media (max-width: 1024px) {
    .aiandrobocontent {
        padding: 10px;
    }

    .para1_robo h1 {
        font-size: 1.75rem;
    }

    .para1_robo p {
        font-size: 1.125rem;
    }

    .pa {
        justify-content: space-around;
    }

    .pa1, .pa2, .pa3, .pa4 {
        width: 45%;
    }
}

@media (max-width: 768px) {
    .aiandrobocontent {
        flex-direction: column;
        align-items: center;
    }

    .para1_robo {
        text-align: center;
        margin-bottom: 20px;
    }

    .para1_robo p {
        text-align: center;
        font-size: 1rem;
    }

    .aibutton {
        width: 100%;
        margin-top: 10px;
    }

    .pa {
        flex-direction: column;
        align-items: center;
        margin-top: -60px;
    }

    .pa1, .pa2, .pa3, .pa4 {
        width: 100%;
        
    }
    .pa3, .pa4{
        position: relative;
        bottom: 60px;
        
    }
}

@media (max-width: 480px) {
    .para1_robo h1 {
        font-size: 1.5rem;
    }

    .para1_robo p {
        font-size: 0.875rem;
    }

    .aibutton {
        width: 100%;
    }

    .pa {
        flex-direction: column;
        align-items: center;
    }

    .pa1, .pa2, .pa3, .pa4 {
        width: 100%;
    }
}
